<template>
  <div class="our_people">
    <section>
      <article>
        <article id="hometop">
          <br /><br /><br /><br /><br />
          <main>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h4
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    class="animated slideInUp"
                  >
                    <strong>Our People</strong>
                  </h4>
                  <hr
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    style="background-color: #fafafa; height: 0.0001px"
                  />
                </div>
              </div>
              <br /><br />
              <div class="row">
                <div
                  class="col-lg3 col-md-6 col-sm-6 col-xs-6 mb-5"
                  v-for="leader in leaders"
                  :key="leader.name"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div>
                    <div class="leader_image">
                      <img :src="leader.imageUrl"/>
                    </div>
                    <h5 style="color: black">
                      <strong>{{ leader.name }}</strong>
                    </h5>
                  </div>
                  <p style="color: grey">{{ leader.position }}</p>
                  <el-collapse
                    v-model="leader.activeNames"
                    @change="handleChange"
                  >
                    <el-collapse-item title="See Details" name="0">
                      <div>{{ leader.about }}</div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
              <br />
              <br />
              <div class="mb-5">
                <h2 style="font-size: 1.2em; font-weight: 600;">The Locus Team</h2>
                <hr/>
                <img src="../assets/images/products/mat.jpg" width="100%">
              </div>

              <br /><br /><br />
            </div>
          </main>
        </article>
      </article>
    </section>
  </div>
</template>

<script>

export default {
  
  data() {
    return {
      activeNames: [],
      leaders: [
        {
          activeNames: "1",
          name: "Mr. Patrick G. Barugahare",
          position: "CEO",
          imageUrl: require("../assets/images/products/mat.jpg"),
          about:
            "Mr. Patrick G. Barugahare is the Majority shareholder and Director of the Locus Hotel Makindye. He is an accomplished Commercial Lawyer with many years of legal practice experience both in Uganda and Australia. Having had a passion for Hospitality, he founded the Locus Hotel in the quest to deliver outstanding service to the Ugandan market and with years of great international exposure in the global high end hotels & restaurant, he chose to found a bespoke boutique hotel to cater for the discerning traveller. Patrick is driven by a strong vision and a passion for excellence and he leads the team with a strong drive for business growth as well as employee development.",
        },
        {
          activeNames: "2",
          name: "Leopold King",
          position: "Senior Manager",
          imageUrl: require("../assets/images/products/mat.jpg"),
          about:
            "Leopold King is a motivated, assertive and highly successful Hotelier with a solid track record of developing businesses, driving forward new products and creating strategies which exploit opportunities and maximize profitability in Hotels, Lodges, Luxury Camps, Resorts and other businesses outside the hospitality industry. He demonstrates extensive experience (21 years) of building business visions and delivering projects in very competitive environments; renowned for achieving great results with leading Hotel and hospitality companies which include Sheraton, Hilton, Sanctuary Retreats (Abercrombie & Kent), Mantis, Best Western among others he rings his expertise in unrivalled guest service, product and employee development.",
        },
        {
          activeNames: "3",
          name: "Chef Patrick Maina",
          position: "Executive Chef",
          imageUrl: require("../assets/images/products/mat.jpg"),
          about:
            "“The man with gifted hands!” Chef Patrick started his culinary journey 15 years ago when he realized his passion for cookery. He trained under the best Chefs in Leading Hotels and restaurants which include Serena, Le Chateau, Marasa Africa group where he led the Kitchen brigade in several properties before coming in to be the pioneer Chef at Locus Hotel. He demonstrates a keen sense of natural flavours’ and very artistic presentation to his cuisine. Those who have had the opportunity to dine at the Locus Hotel testify of his attention to detail and the evidence of his secret ingredient…. Tender, Loving Care in all his dishes. He is a great leader who is dedicated to developing great chefs along his career",
        },
        {
          activeNames: "4",
          name: "Nabuufu Whitney",
          position: "Guest Relations Officer",
          imageUrl: require("../assets/images/products/mat.jpg"),
          about:
            "Young, Energetic and Enthusiastic about great service, Whitney graduated in Hotel Management from Nkumba University after acquiring a degree in Fashion and Textiles. She leaned towards the Hotel Industry due to her people skills and has never looked back then. She has horned her skills as a great Guest Relations Officer who will go to great lengths to ensure our guests at Locus Hotel get the best experience in the country. Whitney is truly the face of Locus Hotel’s signature hospitality tailored to make every guest feel like royalty",
        },
      ],
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
main {
  text-align: left;
}

.leader_image {
  background-color: #c0c4cc;
  height: 400px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leader_image img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>

<style>
    
  .our_people .el-collapse-item__header {
    background-color: transparent;
    border-bottom: 1px solid #EBEEF5;
    color: #3D290A;
    text-align: left;
  }
  .our_people .el-collapse-item {
    background-color: transparent;
    color: #3D290A;
    text-align: left;
  }
  .our_people .el-collapse-item__content {
    border: none;
    background-color: transparent;
    color: #3D290A;
    text-align: left;
  }
  .our_people .el-collapse-item__wrap {
      will-change: height;
      background-color: transparent;
      overflow: hidden;
      box-sizing: border-box;
      border-bottom: 1px solid #EBEEF5;
  }

</style>